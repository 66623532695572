.flowsSelection__wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  margin: 32px 64px;
}

.flowsSelection__header {
  display: flex;
  justify-content: space-between;
}

.flowsSelection__header-left {
  align-items: center;
  display: flex;
  gap: 16px;
}

.flowsSelection__header-toggle {
  align-items: center;
  display: flex;
  gap: 13px;
}

.flowsSelection__header-toggle span {
  color: var(--neutral-50, #73788c);
  /* Text/caption/12R */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.flowsSelection__header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flowsSelection__header-right span {
  color: var(--neutral-50, #73788c);
  /* Text/caption/12R */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.flowsSelection__header-right div {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.flowsSelection__title {
  /* Text/H6/20M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.textAlign__center {
  text-align: center;
}

.flowsSelection__column-width1x {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
}

.flowSelection__table-row {
  position: relative;
  box-sizing: border-box;
  align-items: center;
  background: var(--neutral-100, #fff);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  /* display: grid; */
  gap: 16px;
  /* grid-template-columns: 2fr 1fr; */
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 1px 1px 4px 0px rgba(69, 72, 84, 0.08);
  /* display: grid; */
}

.flowSelection__table-row:hover {
  background-color: #e0eafa;
}

.flowSelection__leftDetail-wrapper {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  justify-content: center;
  display: grid;
  grid-template-columns: 1.05fr 1fr 64px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.flowSelection__msg {
  display: flex;
  align-items: center;
}

.flowSelection__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flowSelection__left-wrapper {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  justify-content: center;
  display: grid;
  /* grid-template-columns: 1.25fr 64px 1fr; */
  grid-template-columns: 1.05fr 64px 1fr;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.flowSelection__table-rowData {
  align-items: center;
  gap: 8px;
  padding: 10px 8px;
  height: 100%;
  box-sizing: border-box;
}

.flowSelection__table-rowData span:first-child {
  color: var(--neutral-10, #17181c);
  /* Text/Subtitle/16M */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.flowSelection__table-rowData span:last-child {
  color: var(--neutral-50, #73788c) !important;
  /* Text/Subtitle/14M */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}

#flowSelection__newMsg {
  color: var(--neutral-70, #abafbb);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.flowSelection__right-wrapper {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  gap: 32px;
  max-width: 276px !important;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flowSelection__right-wrapper div {
  align-items: center;
  display: flex;
  gap: 8px;
}

.flowSelection__right-wrapper div span {
  color: var(--neutral-10, #17181c);
  /* Text/caption/12R */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.modal__statusInfo-wrapper {
  display: flex;
  justify-content: flex-start;
  height: 502px;
}

.modal__header-main {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.modal__xMark {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modal__xMark svg {
  cursor: pointer;
}

.modal__statusInfo-text {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  overflow: auto;
}

.modal__statusInfo-textItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.modal__statusInfo-textItem div span {
  color: var(--neutral-10, #17181c);
  /* Text/Body2/14R */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
}
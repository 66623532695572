.admin__content {
  background: #fcfcfd;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: calc(100% - 80px);
  min-height: calc(100vh - 48px);
  position: relative;
  top: 0;
  left: 80px;
}

.admin__content-expanded {
  background: #fcfcfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: calc(100% - 236px - 48px);
  /* 236px left - (24px * 2) gap - [2px border] */
  min-height: calc(100vh - 48px);
  position: relative;
  top: 0;
  left: 236px;
}

.admin__topBar {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.admin__topBar-title {
  text-decoration: none;
  cursor: pointer;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__selected-onboardingFlow {
  cursor: pointer;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  /* Primary/50 */
  color: #2468db;
}

.admin__topBar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.admin__searchNav {
  /* Searchbar */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 398px;
}

.admin__searchNav-content {
  /* Content */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  width: 398px;
  height: 48px;
  /* Neutral / 99 */
  background: #fcfcfd;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.admin__searchNav-left {
  /* Left */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 0px;
  gap: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.admin__searchNav-right {
  /* Right */
  /* Auto layout */
  display: flex;
}

.admin__searchNav-input {
  box-sizing: border-box;
  width: 275px;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #5c6070;
  border: none;
  background-color: inherit;
  padding: 0px 8px;
}

.button__searchNav {
  background-color: inherit;
  width: 25px;
  height: 21px;
  /* Text/Button/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* Neutral / 60 */
  color: #8f93a3;
  border: none;
}

.admin__topBar-orgStatus {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 8px;
  width: 238px;
  height: 40px;
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__topBar-profile {
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 10px;
  border-radius: 24px;
  width: 31.92px;
  height: 32px;
}

.admin__cards {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.admin__cards-org {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 24px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.admin__card {
  /* Card / Overview section */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 33%;
  height: 100%;
  min-height: 94px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
}

.admin__card-header {
  /* Header */
  /* Auto layout */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 8px;
  width: 100%;
}

.admin__card-headerTop {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

.admin__card-headerText {
  /* Header title text */
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__card-headerTrend {
  /* Trend mini */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
}

.admin__card-headerUpDown {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.admin__card-UpDownText {
  /* text */
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__card-UpDownIcon {
  display: flex;
}

.admin__card-headerDescription {
  /* Text/OVERLINE/10M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: uppercase;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__card-headerBottom {
  /* Text/Subtitle/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__title {
  /* Title */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.admin__title-row {
  /* Title */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;
}

.admin__title-wrapper {
  display: flex;
  gap: 16px;
}

.admin__titleText {
  /* Text/H6/20M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / black */
  color: #0a141e;
}

.admin__titleDescription {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__tcommands {
  /* Commands */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.admin__filters {
  /* Filters */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}

.button__dashboard-filters {
  min-height: 24px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 10px;
  min-width: 91px;
  gap: 8px;
}

.button__filter-flex {
  display: flex;
}

.admin__tbuttons {
  /* Buttons */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 32px;
  height: 24px;
  border-radius: 8px;
}

.button__dashboard-auxiliar {
  width: 32px;
  min-height: 24px;
  max-height: 24px;
  border: none;
  padding: 0px;
  background-color: inherit;
}

.admin__wrapper-table {
  width: 100%;
}

.admin__wrapper-cards {
  /* Cards */
  /* Auto layout */
  display: flex;
  width: 25%;
  flex: 1;
}

.admin__header-panel {
  /* Header panel notification */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.admin__header-card {
  /* Card / Overview section */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 100%;
  /* Neutral / 100 */
  background: #ffffff;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
}

.admin__header-wrapperC {
  /* Header */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.admin__header-cardTop {
  /* top */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.admin__hCardTop-text {
  /* Header title text */
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__hCardTop-icon {
  /* flag */
  width: 24px;
  height: 24px;
}

.admin__header-cardBottom {
  /* bottom */
  width: 100%;
  /* Text/Subtitle/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__header-cardBottomC {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px 0px 0px;
}

.admin__contact {
  text-decoration: none;
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Semantic/Info */
  display: block;
  color: #794cff;
}

.admin__wrapper-content {
  box-sizing: border-box;
  /* Main panel */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px;
  gap: 24px;
  /* Neutral / 100 */
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
}

.admin__table {
  font-family: "Poppins";
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  background-color: transparent;
}

.admin__table-header {
  width: 100%;
}

.admin__table-headerC {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  border-radius: 8px;
  width: 100%;
}

.admin__table-columnTitle {
  height: 21px;
  /* Text/Subtitle2/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__table-columnTitle:first-of-type {
  text-align: initial;
}

.column-width2x {
  width: 22%;
  height: 100%;
  box-sizing: border-box;
}

.column-width1x {
  width: 13%;
  height: 100%;
  box-sizing: border-box;
}

.admin__table-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.text__align-center {
  text-align: center;
}

.admin__violetText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  /* Semantic/Info */
  color: #794cff;
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.admin__navTab-wrapper {
  background: #ffffff;
  display: flex;
  align-items: center;
  padding-right: 0px;
  gap: 24px;
  height: 55px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid var(--neutral-95, #f1f2f4);
  width: 100%;
}

.admin__navTab-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: rgba(255, 255, 255, 0.01);
  cursor: pointer;
}

.admin__navTab-section:hover {
  background-color: #e0eafa;
}

.admin__navTab-sectionActive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  background: rgba(255, 255, 255, 0.01);
  border-bottom: 2px solid #2468db;
}

.admin__navTab-sectionName {
  height: 21px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Primary/50 */
  color: #17181c;
}

.admin__navTab-sectionNameActive {
  height: 21px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Primary/50 */
  color: #2468db;
}

.admin__stepper-section {
  /* Organization info */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
  width: 100%;
}

.admin__stepper-sectionContent {
  /* Content */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.admin__stepper-sectionHeader {
  /* Header */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.admin__stepper-sectionHeaderT {
  /* Title */
  /* Text/H6/20M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__stepper-sectionHeaderSubt {
  /* Text/Subtitle/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  /* Neutral / black */
  color: #0a141e;
}

.button__edit-section {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 32px;
  min-height: 32px;
  /* Primary/50 */
  border-radius: 8px;
}

.admin__stepper-sectionHeaderE {
  /* Editor options */
  /* Auto layout */
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.admin__stepper-lastUpdated {
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 40 */
  color: #5c6070;
}

.admin__dividerSection {
  width: 100%;
  border: 1px solid #e4e5e9;
  margin: auto;
}

.button__align-center {
  margin: auto;
  margin-top: 20px;
}

.admin__modal-overaly {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin__stepSection-wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  width: 100%;
}

.admin__textAreaBox {
  resize: none;
  box-sizing: border-box;
  width: 100%;
  height: 122px;
  /* Auto layout */
  display: flex;
  padding: 10px 10px 10px 16px;
  gap: 10px;
  /* Neutral / 99 */
  background: #fcfcfd;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  /* Inside auto layout */
  align-self: stretch;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__dropDownBox {
  appearance: none;
  -moz-appearance: none;
  /* FireFox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  box-sizing: border-box;
  /* Auto layout */
  padding: 10px 10px 10px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  /* Neutral / 99 */
  background-color: #fcfcfd;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  /* Inside auto layout */
  align-self: stretch;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
  /* Down arrow */
  background-image: url("../../../assets/arrow_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 20px;
}

.actionableCard {
  cursor: pointer;
  width: 100% !important;
  height: 156px;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(69 72 84 / 8%);
  border-radius: 8px;
}

.uploadFile-card-active {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100% !important;
  height: 156px;
  background: #ffffff;
  border: 1px solid #2468db;
  box-shadow: 1px 1px 4px rgb(69 72 84 / 8%);
  border-radius: 10px;
}

.uploadFile-card {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100% !important;
  height: 156px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Primary / 80 */
  border: 1px dashed #a8c2f0;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 10px;
}

.admin__disabled-color {
  color: #5c6070 !important;
}

.admin__disabled-background {
  background-color: #e4e5e8 !important;
}

.min-height-50vh {
  min-height: 50vh;
}

.modal__backdrop {
  background: rgba(221, 221, 221, 0.65);
  background: rgba(10, 20, 30, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}

.modal__header {
  /* Top */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.modal__header-main {
  /* Text/H6/20M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / black */
  color: #0a141e;
}

.admin__filter-btnErase {
  /* Buttons */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 29px;
  min-height: 29px !important;
  /* Primary/99 */
  background: #f6f9fd;
  /* Primary/50 */
  border: 1px solid #2468db;
  border-radius: 8px;
  /* Text/Button/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.admin__filters-content {
  /* Main */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  height: 100%;
}

.admin__filters_grouping {
  /* Grouping */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 50%;
}

.admin__filters-gList {
  /* List */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.admin__filters-typeT {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__filters-subT {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__filters_calendar {
  width: 50%;
  /* Calendar */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0px;
  gap: 16px;
  /* width: 388px;
height: 189px; */
  /* Elevation */
  filter: drop-shadow(0px 4px 16px #f7f7fa);
  border-radius: 4px;
}

.modal__footer {
  /* CTAs */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.admin__hideScrollY {
  height: 100vh;
  overflow-y: hidden;
}

.admin__hideScrollY-aside {
  height: calc(100vh - 48px);
  overflow-y: hidden;
}

.admin__filter-modal {
  justify-content: space-between;
}

.admin__filters-options {
  /* Options */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
}

.admin__filter-wrapperOpt {
  /* Item */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.admin__filters-optionSelect {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.admin__filter-textOpt {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.modal__divider {
  margin-top: 12px;
  border: 1px solid #e4e5e9;
  width: 100%;
}

.admin__filters-datePicker {
  /* Date picker */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 7px;
  position: absolute;
  top: 83px;
  left: 27px;
}

.admin__filters-dateInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 50%;
}

.chat__bubble-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 40px;
}

.chat__bubble-indexUp {
  position: relative;
  z-index: 35;
}

.chat__bubble-neutral {
  position: relative;
  z-index: 0;
}

.chat__bubble_notificationW {
  position: absolute;
}

.chat__bubble_notification {
  position: relative;
  width: 9px;
  height: 9px;
  bottom: 6px;
  left: 7px;
  /* Semantic/Error */
  background-color: #dd082f;
  border-radius: 20px;
}

.modal__chat-wrapper {
  position: absolute;
}

.modal__chat {
  z-index: 30;
  /* Auto layout */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  position: relative;
  right: 0px;
  top: 210px;
  background-color: #ffffff;
  border: 1px solid #f1f2f4;
  box-shadow: 1px 1px 4px rgb(69 72 84 / 8%);
  border-radius: 8px;
}

.admin__chat-modal {
  max-height: 333px;
  justify-content: space-between;
  gap: 0px;
}

.modal__chat-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal__chat-inputW {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  border: none;
}

.modal__chat-avatar {
  min-height: 100%;
  width: 7%;
}

.modal__chat-textarea {
  /* Neutral / 1 */
  background-color: #ffffff;
  /* Neutral / 5 */
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-sizing: border-box;
  height: 31px;
  width: 100%;
  padding: 5px 12px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.chat__button-commentW {
  align-self: flex-end;
}

.chat__button-comment {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: 98px;
  min-height: 24px !important;
  height: 24px;
  /* Neutral / 60 */
  background-color: #8f93a3;
  border-radius: 8px;
  border: 1px solid #8f93a3;
  /* Text/Button/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* Neutral / 100 */
  color: #ffffff;
}

.chat__button-comment:hover {
  background-color: darkgray;
}

.chat__avatar-content {
  display: flex;
  justify-content: center;
}

.chat__avatar-circle {
  justify-content: center;
  padding: 0px;
  gap: 10px;
  letter-spacing: 0.1px;
  width: 32px;
  height: 32px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-radius: 56px;
}

.chat__avatar_eqFrom {
  background-color: #cc3f00;
}

.chat__avatar_neqFrom {
  background-color: #ff7233;
}

.admin__chat-main {
  width: 100%;
  height: 100%;
  max-height: 176px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.chat__overflowY-hidden {
  overflow-y: hidden !important;
}

.admin__chat-msgs {
  display: flex;
  height: 59px;
  gap: 16px;
}

.chat__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat__content-msg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 20 */
  color: #2e3038;
}

.chat__content-date {
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Character / Secondary .45 */
  color: rgba(0, 0, 0, 0.45);
}

.modal__sendNewFlow-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px;
  width: 100%;
}

.modal__sendNewFlow-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px;
  width: 100%;
}

.modal__sendNewFlow-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px;
  width: 100%;
}

.modal__upper-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.modal__title-description {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  /* Neutral/80% */
  color: #394452;
}

.style__width-100 {
  width: 100%;
}
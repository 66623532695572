.builderError__container {
    display: grid;
    grid-template-columns: 60% auto;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}

.builderError__left-statusCode {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 120px;
    letter-spacing: 1.5px;
    color: #101010;
}

.builderError__left-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: #101010;
}

.builderError__left-description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: #101010;
}
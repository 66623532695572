.actionableCard {
  cursor: pointer;
  width: 466px;
  height: 156px;
  border: 1px solid #f1f2f4;
  /* Card / Actionable */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 8px;
}

.headerCard {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 56px;
  align-self: stretch;
}

.textCard {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  /* Inside auto layout */
  align-self: stretch;
}

.headerImgCard {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  width: 112px;
  height: 56px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.headerRadioCard {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;

  width: 24px;
  height: 24px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.imageCard {
  border-radius: 11.2px;
  width: 100%;
}

.titleCard {
  /* Text/Subtitle1/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;

  /* Neutral / 20 */
  color: #2e3038;
}

.descriptionCard {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;

  /* Neutral / 50 */
  color: #73788c;
}

.activeCard {
  /* Primary / 50 */
  border: 1px solid #2468db;
}

.normalcard {
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
}

.uploadCard {
  /* Primary / 80 */
  border: 1px dashed #a8c2f0;
}

.largeCard__disabledCard {
  cursor: auto !important;
  background-color: #e4e5e8 !important;
}

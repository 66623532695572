.typeViewNav__header {
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--neutral-95, #f1f2f4);
    display: flex;
    justify-content: space-between;
    padding-right: 0px;
}

.typeViewNav__nav {
    align-items: flex-start;
    display: flex;
    gap: 8px;
}

.typeViewNav__item {
    align-items: center;
    display: flex;
    padding: 16px;
    justify-content: center;
    gap: 16px;
    /*  */
    /* Text/Body2/14R */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
}

.admin__navTab-sectionActive {
    height: 21px;
    /* Text/Body2/14R */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    /* Primary/50 */
    color: #2468db;
}
.button-class {
  /* Buttons */
  box-sizing: border-box;
  cursor: pointer;
  /* Text/Button/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* Identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* Neutral / 100 */
  color: #ffffff;
  border: none;
  border-radius: 8px;
}

.buttonBase {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 32px;
  min-height: 56px;
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.disabled {
  /* Neutral / 60 */
  background-color: #8f93a3;
  cursor: auto;
}

.active {
  /* Primary / 50 */
  background: #2468db;
}

.active:hover {
  background-color: #1d53af;
}

.secondary {
  /* Primary/99 */
  background: #f6f9fd;
  /* Primary / 50 */
  border: 1.5px solid #2468db;
  /* Primary / 50 */
  color: #2468db;
}

.secondary:hover {
  background-color: #e0eafa;
}

.transparent {
  background-color: transparent;
  color: #2468db;
}

.transparent:hover {
  background-color: #e0eafa;
}

.pill {
  background-color: #794cff;
  border-radius: 32px;
  gap: 8px;
  min-height: 45px;
  padding: 8px 16px;
}

.accountPage__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100vw;
  /* Text/Subtitle2/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.accountPage__footer span {
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Neutral / 40 */
  color: #5c6070;
}

.accountPage__footer a {
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* Primary / 50 */
  color: #2468db;
}

.button__margin-auto {
  margin: auto;
}

.button__flex {
  display: flex;
}
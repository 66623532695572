.accountPage__wrapper {
  background-color: #ffffff;
  padding: 56px 64px;
  /* Flex */
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.admin__page {
  min-height: 100vh;
}

.statusOnboarding {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  min-height: 24px;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.statusPending {
  background-color: var(--semantic-warning-95, #fff3e5);
  color: var(--semantic-warning-35, #a85b00);
}

.statusApprove {
  background-color: #e6fff3;
  color: #00c266;
}

.statusDenied {
  background-color: #fff5f7;
  color: #dd082f;
}

.statusOther {
  background-color: #f6f1fd;
  color: #6719e6;
}

.statusSent {
  background-color: var(--semantic-alter-95, #e7e8fe);
  color: var(--semantic-alter-40, #0a13c2);
}
.flowTypeVersions__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.flowTypeVersions__title {
    color: var(--neutral-black, #0a141e);
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 0px 16px;
    /* Text/Subtitle/16M */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
}

.flowTypeVersions__section {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding: 0px 16px;
}

.flowTypeVersions__section-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 16px;
    padding: 16px;
}

.flowTypeVersions__divider-container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.flowTypeVersions__divider {
    height: 1px;
    width: 98%;
    background: #e4e5e8;
    align-items: center;
    display: flex;
    justify-content: center;
}

.flowTypeVersions__seeAll {
    display: flex;
    height: 24px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
}

.flowTypeVersions__seeAll-btn {
    cursor: pointer;
    /* Content */
    color: var(--primary-50, #2468db);
    text-align: center;
    /* Text/Button/14M */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.25px;
    text-transform: capitalize;
}
.modalWrapper {
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  padding: 24px 32px;
  /* Flex */
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.modal__icon-img {
  width: 120px;
  height: 120px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  /* Image */
  display: flex;
  align-items: center;
}

.modal__wrapper-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__title {
  text-align: center;
  /* Text/Subtitle1/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
  /* Icon */
  margin-right: 6px;
}

.modal__description-text {
  width: 380px;
  /* FGL Modal's width is tie to this */
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.modal__twoButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 56px;
  align-self: stretch;
}

.modal_wrapper_confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 32px 44px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(69 72 84 / 8%);
  border-radius: 16px;
  width: 486px;
}
.textarea__wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
}

.textarea__label-name {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #17181c;
  margin-bottom: 8px;
}

.textarea__box {
  resize: none;
  box-sizing: border-box;
  display: flex;
  padding: 10px 10px 10px 16px;
  gap: 10px;
  background-color: #fcfcfd;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  align-self: stretch;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #17181c;
}

.textarea__box-default {
  width: 100%;
  min-height: 122px;
}

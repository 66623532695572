.mainSection {
  /* Auto layout */
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  padding: 32px 64px;
  gap: 24px;
  isolation: isolate;
  /* Inside auto layout */
  order: 1;
}

.admin__dashboard-loader {
  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.left_section {
  /* Left */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
  gap: 40px;
}

.left__section-upper {
  /* Upper */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.left__section-resources {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.left__resources-wrapperT {
  /* Text/Subtitle/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
}

.left__resources-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45%;
}

.left__resources-title {
  /* Text/Subtitle/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
}

.left__resources-cards {
  /* Auto layout */
  padding: 0px;
  gap: 24px;
  height: 239px;
  display: grid;
  gap: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  width: 100%;
}

.left__resources-card {
  /* Cards / Resources */
  /* Auto layout */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  width: 367px;
  height: 239px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 8px;
}

.left__resources-title {
  /* Text/Subtitle1/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Primary/50 */
  color: #2468db;
}

.left__resources-desc {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  /* Neutral / 50 */
  color: #73788c;
  mix-blend-mode: normal;
}

.left__section-wrapper {
  /* Left */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.dashboard__leftSection-banner {
  /* Banner */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: 100%;
  background: linear-gradient(92.19deg, #0047ff 13.5%, #005fff 101.05%);
  border-radius: 8px;
}

.dashboard__leftSection-imageWrapper {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 10px;
  isolation: isolate;
  width: 318px;
}

.dasboard__leftSection-imgFilter {
  position: absolute;
  width: 374px;
  height: 266px;
  left: -25px;
  top: -25px;
  background: linear-gradient(
    99.81deg,
    #ebebeb 51.06%,
    rgba(217, 217, 217, 0) 84.46%
  );
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.dashboard__leftSection-image {
  border-radius: 8px 0px 0px 8px;
  min-width: 49%;
  display: flex;
  justify-content: center;
}

.dashboard__leftSection-content {
  z-index: 2;
  /* Contents */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 366px;
  min-height: 224px;
  justify-content: center;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.dashboard__leftSection-text {
  /* Text */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.dashboard__leftSection-title {
  /* Title */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  /* Semantic/Warning */
  color: #ff8a00;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.dashboard__leftSection-subtitle {
  /* Subtitle */
  /* Text/Body1/16R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  /* Neutral / 100 */
  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding-right: 10px;
}

.dashboard__leftSection-commands {
  /* Commands */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  gap: 306px;
  width: 366px;
  height: 48px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.dashboard__leftSection-cards {
  /* Cards */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 760px;
  height: 124px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.dashboard__cardMain {
  /* Card / Main actions */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px 16px 24px; */
  gap: 4px;
  /* width: 237.33px; */
  height: 124px;
  /* Primary/99 */
  background: #f6f9fd;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}

.dashboard__cardMain-icon {
  /* complex icon */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 56px;
  height: 56px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.dashboard__cardMain-text {
  /* Text */
  /* width: 126px;
    height: 20px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: 0.25px;
  color: #2468db;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.task_panel {
  /* Task panel */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px;
  gap: 24px;
  width: 370px;
  height: 100%;
  /* Neutral / white */
  background: #ffffff;
  /* Neutral/30% */
  border: 1px solid #dadee3;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
}

.upper {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: max-content;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.overview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  height: 82px;
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: 58px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.header_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 41px;
  width: 100%;
  height: 24px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.header_title {
  /* Text/Subtitle1/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
}

.header_bottom {
  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 40 */
  color: #5c6070;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.progressbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  gap: 8px;
  width: 100%;
  height: 16px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.progressbar_box {
  background: #f1f2f4;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
}

.progressbar_fill {
  background: #2468db;
  border-radius: 9999px;
  height: 100%;
}

.task_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 100%;
  height: 445px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.card_task {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.card_task_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  width: calc(100% - 50px);
  height: 100%;
}

.card_task_text_main {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.card_task_label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Neutral / 10 */
  color: #17181c;
}

.card_task_description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  /* Neutral / 50 */
  color: #73788c;
  mix-blend-mode: normal;
}

.card_separator {
  width: 100%;
  height: 0px;
  /* Neutral / 80 */
  border: 1px solid #c7c9d1;
  margin-top: 16px;
}

.dashboard__cartButton-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.dashboard__cardButton {
  box-sizing: border-box;
  /* Auto layout */
  flex-direction: row;
  justify-content: center;
  padding: 4px 12px;
  width: 73px;
  /* Primary/99 */
  background: #f6f9fd;
  /* Primary / 50 */
  border: 1px solid #2468db;
  border-radius: 8px;
  cursor: pointer;
  /* Text inside button */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  /* Primary / 50 */
  color: #2468db;
}

.dashboard__pill {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 181px;
  height: 20px;
  border-radius: 4px;
}

.dashboard__pill-red {
  width: 8px;
  height: 8px;
  /* Semantic/Error */
  background: #dd082f;
  border-radius: 9px;
}

.dashboard__pill-unread {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: capitalize;
  color: rgba(171, 175, 187, 1);
}

.loader__alignSelf {
  align-self: center;
  height: 100%;
}

.checkbox__wrapper {
  display: flex;
  gap: 16px;
  width: fit-content;
}

.checkbox__wrapper-normalGap {
  display: flex;
  gap: 8px;
}

.checkbox__label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #17181c;
}

.checkbox-labelName {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #17181c;
}

.checkbox__cursor-pointer {
  cursor: pointer !important;
}
.admin__builder-cardWrapper {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    width: 368px;
    /* Neutral / 100 */
    background-color: #ffffff;
    /* Neutral / 95 */
    border: 1px solid #f1f2f4;
    border-radius: 8px;
}

.admin__builder-cardWrapper:hover {
    background-color: #e0eafa;
    cursor: pointer;
}

.admin__builder-card {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 8px;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
}

.admin__builder-cardTop {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.admin__builder-cardBottom {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 41px;
}

.admin__builder-cardName {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
}

.admin__builder-cardNameImg {
    width: 41.45px;
    height: 100%;
}

.admin__builder-cardNameTitle {
    /* Text/Subtitle/16M */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: 0.15px;
    /* Primary / 10 */
    color: #07152c;
}

.admin__builder-cardStatusIcon {
    height: 24px;
}

.admin__builder-cardItem {
    /* Text/caption/12R */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    /* Neutral / 40 */
    color: #5c6070;
}

.admin__builder-cardItemValue {
    font-weight: 500;
    color: #07152c;
}
.input__date {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 16px;
  gap: 10px;
  /* Neutral / 99 */
  background-color: #fcfcfd;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  /* Inside auto layout */
  align-self: stretch;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.input__date-default {
  width: 100%;
  height: 48px;
}

.input__date-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #17181c;
}

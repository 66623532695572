.uploadFile-mb {
  display: block;
  margin-bottom: 29px;
}

.uploadFile-card-active {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 466px;
  height: 156px;
  background: #ffffff;
  border: 1px solid #2468db;
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 10px;
}

.active-disabled {
  background-color: #e4e5e8 !important;
}

.uploadFile-card {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 466px;
  height: 156px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Primary / 80 */
  border: 1px dashed #a8c2f0;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 10px;
}

.card-disabled {
  background-color: #e4e5e8 !important;
  border: none;
}

.uploadFile-logo {
  font-family: "Poppins";
  margin: auto;
  min-width: 80px;
  max-height: 80px;
  object-fit: contain;
}

.uploadFile-text {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  /* Inside auto layout */
  align-self: stretch;
}

.input-file-upload {
  display: none;
}

#label-file-upload {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.input__file-disabled {
  cursor: auto !important;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.uploadFile__container-anchor {
  display: flex;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #73788c;
  white-space: break-spaces;
}

.uploadFile-anchor {
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  text-decoration: none;
  color: #73788c;
  white-space: break-spaces;
}

.uploadFile-underline_blue {
  text-decoration: underline;
  color: #2468db;
}

.upload__descriptionFile {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  /* Neutral / 50 */
  color: #73788c;
}

.uploadFile-header {
  font-family: "Poppins";
  margin: auto;
  min-width: 80px;
  max-height: 80px;
  object-fit: contain;
}
.dropdown__box {
  appearance: none;
  -moz-appearance: none; /* FireFox */
  -webkit-appearance: none; /* Safari and Chrome */
  box-sizing: border-box;
  /* Auto layout */
  padding: 10px 10px 10px 16px;
  gap: 10px;
  /* Neutral / 99 */
  background-color: #fcfcfd;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  /* Inside auto layout */
  align-self: stretch;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
  /* Down arrow */
  background-image: url("../../../assets/arrow_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 20px;
}

.dropdown__box-default {
  width: 100%;
  height: 48px;
}

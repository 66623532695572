.step__needsAttention {
    background-color: #fff8f0 !important;
}

.step__needsAttention:hover {
    background-color: var(--semantic-warning-85, #ffdcb2) !important;
}

.step__default {
    background-color: var(--primary-99, #f6f9fd) !important;
}

.step__default:hover {
    background-color: var(--primary-90, #e0eafa) !important;
}

.step__denied {
    background-color: var(--neutral-95, #f1f2f4) !important;
}

.step__denied:hover {
    background-color: var(--neutral-90, #e4e5e8) !important;
}

.step__warning {
    background-color: var(--semantic-warning-50, #ff8a00);
    border-radius: 8px 0px 0px 8px;
    position: absolute;
    left: 0;
    height: 72px;
    width: 8px;
}
.wrapper-inputString {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.input-labelName {
  width: 100%;
  height: 100%;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.inputComponent {
  box-sizing: border-box;
  /* Flex */
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  /*  */
  background-color: #fcfcfd;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  height: 48px;
  width: min(500px, 80vw);
  padding: 10px 10px 10px 16px;
  /*  */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #17181c;
}

.inputComponent-disabled {
  background-color: #e4e5e8;
  color: #5c6070;
}

.inputError {
  outline: none !important;
  border-color: #dd082f !important;
}

.input-wrapperError {
  display: flex;
  align-self: auto;
  align-items: center;
  margin-top: 6px;
}

.input-accountErrorText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Semantic/Error */
  color: #dd082f;
  margin-left: 6px;
}

.input-accountInformativeText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  /* Neutral / 50 */
  color: #73788c;
}

.iconRight {
  position: absolute;
  top: 30%;
  right: 4%;
}
.admin__table-row {
    align-items: center;
    background: var(--primary-99, #f6f9fd);
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    padding: 8px 8px 8px 16px;
    width: 100%;
}

.admin__table-row:hover {
    background-color: #e0eafa;
    cursor: pointer;
}

.admin__table-rowData {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.25px;
    color: #2f2f2f;
}

.admin__table-rowData:first-of-type {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    /* max-width: 234px; */
    /* height: 40px; */
}

.admin__table-firstColumnData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1px;
    width: 100%;
    height: 40px;
    margin: 0px;
}

.admin__table-rowDataImage {
    height: 24px;
    width: auto;
}

.tableContent__row-wrapper {
    display: flex;
    padding: 0px 0px 8px 8px;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    background: var(--neutral-100, #fff);
}

.tableContent__table-mainRow {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 8px 8px 8px 16px;
    align-items: flex-start;
    border-radius: 8px;
    background: var(--neutral-100, #fff);
}
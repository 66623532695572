.account__heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #2e3038;
  text-align: center;
}

.account__dividerSection {
  width: 100%;
  border: 1px solid #e4e5e9;
}

.wrapperForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  gap: 8px;
  width: 564px;
  min-height: 352px;
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 16px;
  flex: none;
  flex-grow: 0;
}

.accountForm {
  width: 100%;
}

.account__forgotPassword {
  cursor: pointer;
  text-decoration: none;
  /* Text/Button/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* Primary/50 */
  color: #2468db;
}

.login__extras-wrapper {
  display: flex;
  justify-content: space-between;
  width: min(566px, 85vw);
}

.account__modalLogin-wrapperInput {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.resetPassword__initial-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.resetPassword__initial-title {
  color: var(--neutral-10, #17181c);
  /* Text/Subtitle/16M */
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.resetPassword__initial-description {
  display: flex;
  width: max(395px, 70%);
  flex-direction: column;
  justify-content: center;
  color: var(--neutral-30, #454854);
  text-align: center;
  /* Text/Body2/14R */
  font-size: 14px;
  font-family: Poppins;
  letter-spacing: 0.25px;
}
.admin__aside {
  width: 80px;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px 0px 48px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  /* Transition */
  transition: width 0.2s ease-out;
  height: 100vh;
}

.admin__aside-expanded {
  width: 236px;
  height: 100vh;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px 0px 48px;
  /* Neutral / 100 */
  background: #ffffff;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  transition: width 0.3s ease-out;
}

.admin__aside-topMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 72px;
  width: 100%;
}

.admin__aside-topMenuOpen {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  /* Neutral / 100 */
  background: #ffffff;
  width: 100%;
  justify-content: space-evenly;
}

.admin__aside-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
}

.admin__aside-icon:last-of-type {
  margin-top: auto;
}

.admin__aside-iconExpanded {
  cursor: pointer;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 16px;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
}

.admin__aside-iconExpanded:last-of-type {
  margin-top: auto;
}

.admin__aside-iconsList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: calc(100vh - 230px);
}

.admin__aside-iconsListExpanded {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  /* Neutral / 100 */
  background-color: #ffffff;
  gap: 8px;
  height: calc(100vh - 230px);
}

.icon-active {
  background-color: #edf3fc;
}

.icon-iactive {
  cursor: pointer;
}

.admin__aside-iconActiveText {
  /* Text/Subtitle/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Primary/50 */
  color: #2468db;
}

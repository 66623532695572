.workFlowList__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.workFlowList__item-wrapper {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0px 16px 0px;
    /*  */
    border-radius: 8px;
    border: 1px solid var(--neutral-95, #f1f2f4);
    background: var(--neutral-100, #fff);
    /* Dropshadow */
    box-shadow: 1px 1px 4px 0px rgba(69, 72, 84, 0.08);
}

.workFlowList__item-main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 8px 16px;
    box-sizing: border-box;
    width: 100%;
}

.workFlowList__item-mainTitle {
    color: var(--neutral-black, #0a141e);
    /* Text/Subtitle/16M */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
}

.workFlowList__flow {
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: space-between;
    padding: 16px 32px;
    width: 100%;
}

.workFlowList__item {
    box-sizing: border-box;
    padding: 0px 16px;
    width: 100%;
}

.workFlowList__parent {
    box-sizing: border-box;
    padding: 0px 16px;
    width: 100%;
}

.workFlowList__parent-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.workFlowList__child0 {
    border-radius: 8px;
    border: 1px solid var(--primary-50, #2468db);
    background: var(--neutral-100, #fff);
}

.workFlowList__child1 {
    border-radius: 8px;
    background: var(--primary-90, #e0eafa);
}

.workFlowList__child2 {
    border-radius: 8px;
    background: var(--primary-95, #f6f9fd);
}

.workFlowList__child3 {
    border-radius: 8px;
    background: var(--primary-95, #f6f9fd);
}

.workFlowList__triggeredBy {
    color: var(--primary-50, #2468db);
    text-align: right;
    /* Text/OVERLINE/10M */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.workFlowList__FlowType-level0 {
    color: var(--neutral-10, #17181c);
    /* Text/Subtitle/16M */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
}

.workFlowList__FlowType-levelgt0 {
    color: var(--neutral-10, #17181c);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.1px;
}

.workFlowList__loader {
    align-items: center;
    display: flex;
    min-height: 40vh;
}

.modal__header-sendWorkflow-div {
    display: flex;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.modal__header-sendWorkflow {
    color: var(--neutral-black, #0a141e);
    /* Text/H6/20M */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.25px;
}
.admin__settingsLinks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.fulldividerSection {
  width: 100%;
  border: 1px solid #e4e5e9;
  margin: auto;
  margin-top: 24px;
}

.topbar {
  box-sizing: border-box;
  /* Auto layout */
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #f4f5f6;
  box-shadow: 0px 10px 110px 1px rgba(59, 59, 59, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 64px;
  position: sticky;
  width: 100%;
}

.topbar_contents {
  box-sizing: border-box;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.topbar_image {
  order: 0;
  object-fit: contain;
  max-height: 24px;
  max-height: 32px;
}

.topbar_image-default {
  order: 0;
  object-fit: contain;
  max-height: 48px;
}

.topbar_sections {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 44px;
  height: 100%;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  text-align: center;
}

.topbar_sections_item {
  box-sizing: border-box;
}

.topbar_sections_item a {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  padding: 12px 16px;
}

.topbar_icon {
  width: 24px;
  height: 24px;
}

.topbar_link {
  text-decoration: none;
}

.topbar_text {
  width: fit-content;
  height: 21px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Primary / 50 */
  color: #2468db;
}

.topbar_user {
  /* Auto layout */
  display: flex;
  gap: 10px;
}

.topbar_user_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
}

.topbar_username {
  width: max-content;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / black */
  color: #17181c;
}

.statusLabel {
  width: 100%;
  height: 15px;
  /* Text/OVERLINE/10M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: uppercase;
  /* Semantic/Warning */
  color: #ff8a00;
}
.admin__onboardingF-wrapper {
  /* Main */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  width: 100%;
  /* Primary/99 */
  background-color: #f6f9fd;
  /* Neutral / 95 */
  border: 1px solid #f1f2f4;
  /* Elevation */
  box-shadow: 0px 4px 16px #f7f7fa;
  border-radius: 8px;
}

.admin__onboardingF-content {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.admin__onboardingF-header {
  width: 100%;
  /* Title */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
}

.admin__onboardingF-headerT {
  /* Text/Subtitle/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Primary/20 */
  color: #0e2a58;
}

.admin__onboardingF-playB {
  /* Buttons */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.admin__onboardingF-playB:hover {
  background-color: #e0eafa;
}

.admin__onboardingF-headerPlay {
  /* Text/Button/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  text-transform: capitalize;
  /* Primary/50 */
  color: #2468db;
  text-decoration: none;
}

.admin__onboardingF-Wcards {
  /* Cards */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.admin__onboardingF-card {
  /* Cards / Notifications */
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: 100%;
  /* Neutral / 100 */
  background-color: #ffffff;
  /* Neutral / 70 */
  border: 1px solid #abafbb;
  border-radius: 8px;
}

.admin__onboardingF-card:hover {
  background-color: #e0eafa;
  /* cursor: pointer; */
}

.admin__onboardingF-cardImg {
  /* Pic */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  border-radius: 24px;
}

.admin__onboardingF-cardText {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
}

.admin__onboardingF-cardTitle {
  /* Label */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Neutral / 10 */
  color: #17181c;
}

.admin__onboardingF-cardDesc {
  /* Description */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.4px;
  /* Neutral / 50 */
  color: #73788c;
  mix-blend-mode: normal;
}
.live__banner-wrapper {
  /* Banner */
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 64px;
  gap: 10px;
  width: 100%;
  /* Primary / 70 */
  background: #7ca4e9;
  box-shadow: 0px 10px 110px 1px rgba(59, 59, 59, 0.08);
  box-sizing: border-box;
}

.live__banner-text {
  /* Text/Subtitle/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Neutral / 99 */
  color: #fcfcfd;
}

:root {
  --circle-size: 16px;
  --spacing: clamp(12px, 1vw, 16px);
}

.wrapper-stepper{
  margin-top: 35px;
}

.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.c-stepper__item:before {
  --size: 3rem;
  content: "";
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #f1f2f4;
  margin: 0 auto 0.1rem;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size) / 2);
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 2px;
  background-color: #c7c9d1;
  order: -1;
}

.c-stepper__title {
  font-size: 12px;
  margin-bottom: 32px;

  /* Text/caption/12R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.4px;

  /* Neutral / 40 */
  color: #5c6070;
}

.c-stepper__active::before {
  background-image: url("../../assets/indicator_active.png");
  background-size: cover;
}

.c-stepper__done::before {
  background-image: url("../../assets/indicator_done.png");
  background-size: cover;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

.error__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 80px;
  width: 100vw;
  height: 100vh;
}

.error__left-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 400px;
  height: 265px;
}

.error__left-error {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 400px;
  height: 185px;
}

.error__left-statusCode {
  width: 400px;
  height: 123px;
  /* Text/H1/80R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 1.5px;
  /* Black */
  color: #101010;
}

.error__left-title {
  width: 400px;
  height: 30px;
  /* Text/H6/20M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Black */
  color: #101010;
}

.error__left-description {
  width: 400px;
  /* Text/Subtitle/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  /* Black */
  color: #101010;
}

.error__last {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  margin: 20px 0px;
  font-weight: 800;
}

.error_rigth-wrapper {
  width: 240px;
  height: 240px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

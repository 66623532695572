.tableDynamic__wrapper {
    grid-column-start: 1;
    grid-column-end: 3;
}

.tableDynamic__wrapper-btn-addNew {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 3;
}

.tableDynamic__btn-addNew {
    color: #2468db;
    cursor: pointer;
    justify-self: end;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    text-decoration: underline;
}

.link__disabled {
    color: #5c6070 !important;
    cursor: auto;
}

.tableDynamic__table {
    font-family: "Poppins";
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.tableDynamic__thead {
    background-color: #f6f9fd;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #e4e5e9;
    padding: 0px 8px;
    width: 100%;
}

.tableDynamic-headerC {
    align-items: center;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    gap: 24px;
    padding: 12px 0px;
}

.tableDynamic-columnTitle {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #5c6070;
    color: black;
    width: 40%;
}

.tableDynamic-columnTitle-action {
    width: 20%;
}

.tableDynamic__body-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.tableDynamic__body-tr {
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    gap: 24px;
    min-height: 32px;
    padding: 4px;
    width: 100%;
    border: 1px solid #e4e5e8;
}

.tableDynamic-row {
    display: flex;
    align-items: center;
    padding: 0px;
    height: 57px;
    border-radius: 8px;
    width: 100%;
}

.tableDynamic__rowData {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #2f2f2f;
    width: 40%;
    padding-left: 10px;
}

.tableDynamic__rowData-disabled {
    width: 50%;
}

.tableDynamic__btn-action {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
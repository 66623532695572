.switch-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.toggle-input {
    display: none;
}

.toggle-label {
    display: block;
    width: 43px;
    height: 24px;
    border: 1px solid #2468db;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.2s;
}

.toggle-circle {
    width: 20px;
    height: 20px;
    background-color: #2468db;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.2s;
}

.toggle-circle.active {
    transform: translateX(100%);
    background-color: #ffffff;
}
.completePage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: min(956px, 90%);
}

.topText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 44px;
}

.titleSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: max-content;
  height: 100%;
}

.titleText {
  width: 100%;
  height: 24px;
  /* Text/Subtitle1/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
}

.descriptionTitle {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.descriptionText {
  width: 70%;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.descriptionText__alert {
  width: 100%;
  margin-bottom: 32px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #dd082f;
}

.formSection {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stepSection-wrapper {
  display: grid;
  gap: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  width: 100%;
}

.stepSection-wrapper-middle {
  padding: 0px 20px;
}

.stepSection-wrapper-summary {
  list-style: none;
}

.sectionTitle {
  /* Text/Subtitle1/16M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.15px;
  /* Neutral / 10 */
  color: #17181c;
  /* Inside auto layout */
  align-self: stretch;
  margin-top: 0px;
}

.flow-dividerSection {
  border: 1px solid #e4e5e9;
  margin: auto;
  margin: 24px 0px;
}

.buttonSection {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 40px;
  width: 100%;
}

.rightSection {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
}

.checkboxInputBox {
  display: flex;
}

.checkboxInputBox-checked {
  margin-right: 10px;
  cursor: pointer;
}

.modalBackDropFilter {
  background: rgba(221, 221, 221, 0.65);
  background: rgba(10, 20, 30, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  overflow-y: scroll;
  position: absolute;
  width: 100vw;
  height: calc(100vh - 72px);
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBackDropFilter__title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 10 */
  color: #17181c;
}

.title__green {
  color: #00c266;
}

.modalBackDropFilter__description {
  width: 668px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.modalBackDropFilter-wrapperImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 13px;
  width: 664px;
  height: 109px;
}

.modalBackDropFilter-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 204px;
  height: 109px;
  /* Dropshadow */
  filter: drop-shadow(1px 1px 4px rgba(69, 72, 84, 0.08));
  border-radius: 8px;
}

.modalBackDropFilter-card img {
  width: 56px;
  height: 56px;
}

.modalBackDropFilter-cardTitle {
  padding: 0px 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #8f93a3;
}

.hideScrollY {
  height: calc(100vh - 72px);
  overflow-y: hidden;
}

.summary__item {
  margin-bottom: 10px;
}

.summary__labelName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.summary__value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.summary__value-red {
  color: #dd082f;
}

.summary__statusAPI-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}

.summary__statusAPI-labelName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  /* Neutral / 30 */
  color: #454854;
  margin-left: 15px;
}

.summary__statusAPI-value {
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 66px;
  /* height: 20px; */
  /* Semantic/Warning BG */
  background: #fff3e6;
  border-radius: 4px;
}

.summary__statusAPI-status {
  width: 50px;
  height: 15px;
  /* Text/OVERLINE/10M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: uppercase;
  /* Semantic/Warning */
  color: #ff8a00;
}

.user__stepper-sectionContent {
  margin-bottom: 18px;
}

.user__disabled-background {
  opacity: 1 !important;
}

.user__notificationCard-wrapper {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  gap: 24px;
  /* height: 80px; */
  /* Semantic/Warning BG */
  background-color: #fff3e6;
  border-radius: 8px;
  margin-bottom: 30px;
}

.user__notificationCard-content {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.user__notificationCard-icon {
  align-self: center;
}

.user__notificationCard-text {
  padding: 10px 0px;
  padding-right: 10px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: 0.25px;
  /* Neutral / 30 */
  color: #454854;
}

.user__notificationCard-textH {
  font-weight: 900;
}

.user__lastNotificationCard-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  background-color: #fff3e6;
  border-radius: 8px;
  margin: 30px 0px;
}

.modalBackDropFilter__card-divider {
  /* Neutral / 90 */
  height: 80px;
  border: 1px solid #e4e5e9;
  align-self: end;
}

.loader__wrapper-withHeader {
  height: calc(100vh - 72px);
}
.combineCard__wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
}

.combineCard__unit {
  width: 50%;
}

.combineCard__largeCard {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.radio__wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
}

.radio__label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #17181c;
  margin-bottom: 8px;
}

.radio__title {
  /* Text/Subtitle2/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.1px;
  /* Neutral / 30 */
  color: #454854;
}

.radio__description {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  /* Neutral / 40 */
  color: #5c6070;
}

.radio__options {
  display: flex;
  align-items: center;
}

.radio__option-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 50%;
}

.radio__option-pointer {
  cursor: pointer;
}

.radio__option-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

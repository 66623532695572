.multipleCheckbox__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  gap: 48px;
}

.multipleCheckbox__option {
  display: flex;
  gap: 8px;
}

.multipleCheckbox__wrapper-input {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 894px;
}

.multipleCheckbox__other-input {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.addElementList {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: auto;
  flex-wrap: wrap;
  gap: 8px;
}

.pillLarge__text {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.25px;
  /* Semantic/Info */
  color: #ffffff;
}
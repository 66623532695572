.docuSign-wrapper {
    box-sizing: border-box;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 16px;
}

.docuSign__title {
    margin: 0px;
    color: var(--neutral-50, #73788c);
    /* Text/Body2/14R */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
}

.docuSign__textDescription {
    margin: 0px;
    color: var(--neutral-50, #73788c);
    /* Text/caption/12R */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}

.docuSign__wrapper-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}
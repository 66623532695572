.inputText__label {
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #17181c;
  margin-bottom: 8px;
}

.inputText__box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: #fcfcfd;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  align-self: stretch;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: #17181c;
}

.inputText__wrapper-halfWidth {
  width: 50%;
}

.inputText__wrapper-general {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.topbar_avatar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 6px;
  gap: 10px;
  /* width: 43.92px;
  height: 40px; */
  /*  border: 1px solid red; */
}

.topbar_avatar-content {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  border-radius: 24px;
  position: relative;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.topbar_circle {
  cursor: pointer;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 40px;
  height: 40px;
  /* Accent/50 */
  background-color: #ffb899;
  color: #ff4f00;
  border-radius: 56px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  /* */
  /* Text/Subtitle/14M */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.1px;
}

.avatar__menu {
  /* Menu item */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  padding: 16px 8px;
  gap: 12px;
  position: absolute;
  width: 171px;
  /* height: 56px; */
  right: 0%;
  top: 110%;
  /* Neutral / 100 */
  background-color: #ffffff;
  /* Dropshadow */
  box-shadow: 0px 2px 4px rgba(23, 24, 28, 0.1);
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 99;
}

.avatar__menu:hover {
  cursor: pointer;
  /* Primary/90 */
  background-color: #e0eafa;
  /* Primary/50 */
  border: 1px solid #2468db;
  /* Dropshadow */
  box-shadow: 1px 1px 4px rgba(69, 72, 84, 0.08);
  border-radius: 4px;
}

.avatar__menu-text {
  /* Log out */
  width: 53px;
  height: 21px;
  /* Text/Body2/14R */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  /* Black */
  color: #101010;
}

.admin__StatusDropDownBox-wrapper {
  display: flex;
}

.admin__StatusDropDownBox-status {
  appearance: none;
  -moz-appearance: none; /* FireFox */
  -webkit-appearance: none; /* Safari and Chrome */
  -webkit-appearance: none;
  box-sizing: border-box;
  background-image: url("../../../assets/arrow_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 20px;
  width: 102px;
  height: 32px;
  border: none;
  cursor: pointer;
}

.admin__StatusDropDownBox-withM {
  width: 145px !important;
}
